// 退换订单
import request from '@/utils/request'

// 查询初始化
export function getPageInit() {
    return request({
      url: "/api/AsinReturnOrder/pageInit",
      method: "get"
    })
}

// 查询分页
export function getListPage(params) {
    return request({
      url: "/api/AsinReturnOrder/listPage",
      params,
      method: "get"  
    })
}

// 下载接口
export function ExportExcel(params) {
  return request({
    url: "/api/AsinReturnOrder/exportExcel",
    params,
    method: "get"
  })
}